<template>
  <div class="process">
        <el-row :gutter="20">
            <el-col :span="14">
                <div class="cont">
                    <div class="process-tit">
                        <h3>企业认证</h3>
                        <p>快捷三步，立即通过</p>
                    </div>
                    <div class="process-cont">
                        <el-steps :active="active" finish-status="success">
                            <el-step title="填写企业名称"></el-step>
                            <el-step title="完善资料" v-if="!have"></el-step>
                            <el-step title="申请加入已有企业" v-if="have"></el-step>
                            <el-step title="等待审核" v-if="passAudit == false"></el-step>
                            <el-step title="审核通过" v-if="passAudit == true"></el-step>
                        </el-steps>
                        <div class="process-item" v-show="active == 1">
                        <div class="item">
                            <label for="">企业名称</label>
                            <div class="item-cont firm">
                                <el-input placeholder="请输入企业名称" v-model="company_name" ></el-input>
                                <!--
                                    @input="checkCompany(company_name)"
                                     <div class="company-list" v-show="companyListShow">
                                    <ul>
                                        <li v-for="(item,index) in company_name_list" :key="index" @click="sureCompany(item)">{{item.name}}</li>
                                        <li v-show="nullList">暂无数据~</li>
                                    </ul>
                                </div> -->
                            </div>
                            <div class="item-btn">
                                <el-button type="warning" v-if="!company_name" disabled>下一步</el-button>
                                <el-button type="warning" @click="next()" v-if="company_name">下一步</el-button>
                            </div>
                        </div>
                        </div>
                        <div class="process-item" v-show="active == 2 && have == false">
                        <div class="item">
                                <label for="">企业名称 </label>
                            <div class="item-cont">
                                    <el-input placeholder="请输入企业名称" v-model="company_name" disabled></el-input>
                            </div>
                        </div>
                        <div class="item">
                            <label for="">企业电话 <span>(请如实填写)</span></label>
                            <div class="item-cont">
                                <el-input placeholder="请输入企业电话" v-model="phone" @input="checkPhone(phone)"></el-input>
                                <div class="error" v-show="phoneShow">
                                    <span class="tip"></span>
                                    <span class="info">请输入正确的手机号</span>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                             <label for="">营业执照 <span style="color: #d81e06;">(复印件必须加盖红章，否则无法通过审核)</span></label>
                             <div class="item-cont">
                                 <div class="up">
                                     <el-upload class="avatar-uploader showUploader"
                                        ref="businessLicense"
                                        action
                                        :auto-upload="false"
                                        :on-preview="handlePreview"
                                        :on-remove="handleRemove"
                                        :before-remove="beforeRemove"
                                        :on-change="uploadSectionFile"
                                        :limit="1">
                                        <img v-if="photo" :src="photo" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                 </div>
                                <div class="eg" title="样本（仅供参考）">
                                    <div class="demo-image__preview">
                                        <el-image 
                                            style="width: 180px; height: 240px"
                                            :src="require('../../assets/model.jpg')" 
                                            :preview-src-list="[require('../../assets/model.jpg')]">
                                            <div class="mask"></div>
                                        </el-image>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <div class="item-btn">
                                <el-button type="warning" @click="last()">上一步</el-button>
                                <el-button type="warning" v-show="!phone || phoneShow == true || photo == ''" disabled>提交</el-button>
                                <el-button type="warning" v-show="phone && phoneShow == false && photo" @click="companySub()">提交</el-button>
                            </div>
                        </div>
                        <div class="process-item" v-show="active == 2 && have == true">
                            <h3>该企业已认证</h3>
                            <h3>您可以“申请加入”,向该企业主账号提交审核</h3>
                            <p>1、加入到该企业后您的资产讲并入企业</p>
                            <p>2、退出企业后资产不可回退，请谨慎加入</p>
                            <p>3、加入企业后您可以使用企业账户的资产</p>
                            <div class="item-btn">
                                <el-button type="warning" @click="last()" >上一步</el-button>
                                <el-button type="warning" @click="apply()">申请加入</el-button>
                            </div>
                        </div>
                        <div class="process-item" v-show="active == 3 && passAudit == false">
                            <div class="audit" v-if="refuse == false">
                                <div class="audit-img">
                                    <img src="../../assets/audit.png" alt="">
                                </div>
                                <h2>待审核，请耐心等待...</h2>
                                <div class="item-btn" style="text-align: center;">
                                    <el-button type="warning" @click="applyR()" >刷新申请结果</el-button>
                                    <!-- <el-button type="success" @click="finish()" v-show="finished == ''">完成</el-button> -->
                                </div>
                            </div>
                            <div class="audit" v-if="refuse == true">
                                <div class="audit-img">
                                    <img src="../../assets/refuse.png" alt="">
                                </div>
                                <h2>不好意思，您被拒绝加入了</h2>
                            </div>
                        </div>
                        <div class="process-item" v-show="active == 3 && passAudit == true">
                            <div class="audit">
                                <div class="audit-img">
                                    <img src="../../assets/passAudit.png" alt="">
                                </div>
                                <h2>审核认证通过</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="10">
                <div class="com">
                    <img src="../../assets/company.png" alt="">
                </div>
            </el-col>
        </el-row>
  </div>
</template>

<script>
    import CertificationProcess from './CertificationProcess.js'
    export default CertificationProcess
</script>

<style scoped>
    @import './CertificationProcess.css';
</style>