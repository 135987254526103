import CertificationProcess from './CertificationProcess'
import axios from 'axios'
import jwt_decode from "jwt-decode";
import bus from '../../bus'
export default {
    components:{
        CertificationProcess
    },
    data(){
        return {
            company_name:'',
            phone:'',
            phoneShow:false,
            licenses:'',
            files: {},
            photo: '',
            url: '../../assets/model.jpg',
            srcList: ['../../assets/model.jpg'],
            companyMsg:{},
            code:'',
            // company_name_list:'',
            // companyListShow:false,
            // nullList:false,
            cid:'',
            active:1,
            fileList: [],
            form: {
                title: '123'
            },
            isAble: false,
            have:false,
            passAudit:false,
            refuse:false,
            finished:localStorage.getItem('finish') || ''
        }
    },
    created(){
        var decoded = jwt_decode(localStorage.getItem('DDTtoken'));
        if(decoded.admin == "1" && decoded.status == '1'){
            this.active = 3
            this.passAudit  = true
        }
        if(decoded.admin == "0" && decoded.status != 0){
            if(decoded.status == '1'){
                this.active = 3
                this.passAudit = true 
            }else {
                this.applyR()
            }
        }
    },
    methods:{
        last(){
            this.active = 1
        },
        handlePreview(file) {
            console.log(file);
          },
        //   上传营业执照
        beforeRemove(file) {
            return this.$confirm(`确定移除 ${ file.name }？`);
        },
        handleRemove(file, fileList) {
            console.log(file, fileList);
        },
        uploadSectionFile(file) {
            console.log(file);
            const typeArr = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg'];
            const isJPG = typeArr.indexOf(file.raw.type) !== -1;
            const isLt3M = file.size / 1024 / 1024 < 10;
            if (!isJPG) {
              this.$message.error('只能是图片!');
              this.$refs.businessLicense.clearFiles();
              this.files = null;
              return;
            }
            if (!isLt3M) {
              this.$message.error('上传图片大小不能超过 10MB!');
              this.$refs.businessLicense.clearFiles();
              this.files = null;
              return;
            }
            this.files = file.raw;
              // FormData 对象
            var formData = new FormData();
            // 文件对象
            formData.append('licenses', this.files);
            let config = {
              headers: {
                'Content-Type': 'multipart/form-data'
              },
              methods: 'post'
            }
            axios.post( this.GLOBAL.BASE_URL1 +'/company/licenses_upload',formData,config)
            .then(res=>{
                if(res.data.status_code == 0){
                    this.photo = res.data.license_url
                    this.companyMsg = res.data.data
                    this.getCode()
                    this.$refs.businessLicense.clearFiles();
                    this.files = null;
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    });
                    this.$refs.businessLicense.clearFiles();
                    this.files = null;
                }
            })
            .catch(error=>{
                console.log(error);
            })
          },
        // 下一步
        next(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/company/company_verification',{
                params:{
                    company_name: this.company_name
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                    if(res.data.companys.length != 0) {
                        this.cid = res.data.companys[0].id
                        this.company_name =  res.data.companys[0].name
                        this.have = true
                    }else {
                        this.have = false
                    }
                    this.active = 2
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    }); 
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 验证手机号
        checkPhone(val) {
            var myreg = /(^1|^84)(\d{10}|\d{9})$/;
            if(!myreg.test(val)) {
                this.phoneShow = true;
            } else {
                this.phoneShow = false;
            }
        },
        // 生成公司代码
        getCode(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/company/creat_company_code',{
                params:{
                    company_name: this.companyMsg.name
                    // company_name:'上海德星嘉意物流有限公司深圳分公司'
                }
            })
            .then(res=>{
                if(res.data.status_code == 0){
                   this.code = res.data.company_code
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    }); 
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        //   提交
        companySub() {
            var fd = new FormData()
            fd.append('org_name',this.companyMsg.name)
            fd.append('org_code',this.companyMsg.reg_num)
            fd.append('legal_rep_name',this.companyMsg.person)
            // fd.append('org_name','上海德星嘉意物流有限公司深圳分公司')
            // fd.append('org_code','9144030034290996XG')
            // fd.append('legal_rep_name','刘时连')
            fd.append('code',this.code)
            fd.append('phone',this.phone)
            fd.append('address',this.companyMsg.address)
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8 ';
            axios.post(this.GLOBAL.BASE_URL1 + '/contract/organization/threeFactor',fd)
            .then(res=>{
                if(res.data.code == 0){
                    this.$message({
                        type: 'success',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    }); 
                    this.active = 3
                    this.passAudit  = true
                    localStorage.setItem('DDTtoken',res.data.token)
                    var data = jwt_decode(res.data.token)
                    bus.$emit('cerUserMsg',data) 
                }else {
                    this.$message({
                        type: 'error',
                        message: res.data.message,
                        showClose: true,
                        center: true
                    }); 
                }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 申请加入已认证企业
        apply(){
            var fd = new FormData()
            fd.append('cid',this.cid)
            axios.defaults.headers.common['Content-Type'] = 'multipart/form-data; charset=UTF-8 ';
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.post(this.GLOBAL.BASE_URL1 + '/user/application_send',fd)
            .then(res=>{
               if(res.data.status_code == 0){
                this.$message({
                    type: 'success',
                    message: res.data.message,
                    showClose: true,
                    center: true
                }); 
                localStorage.setItem('DDTtoken',res.data.token)
                var data = jwt_decode(res.data.token)
                bus.$emit('cerUserMsg',data) 
                this.active = 3
                this.passAudit = false
               }else {
                this.$message({
                    type: 'error',
                    message: res.data.message,
                    showClose: true,
                    center: true
                }); 
               }
            })
            .catch(error=>{
                console.log(error);
            })
        },
        // 查询副账号申请结果
        applyR(){
            axios.defaults.headers.common['token'] = localStorage.getItem('DDTtoken')
            axios.get(this.GLOBAL.BASE_URL1 + '/user/second_apply',{
                params:{}
            })
            .then(res=>{
                // 申请通过
               if(res.data.status_code == 1){
                this.$message({
                    type: 'success',
                    message: res.data.message,
                    showClose: true,
                    center: true
                });
                localStorage.setItem('DDTtoken',res.data.token)
                var data = jwt_decode(res.data.token)
                bus.$emit('cerUserMsg',data) 
                this.active = 3
                this.passAudit = true 
               }
            //    等待审核中
               else if(res.data.status_code == 3){
                this.$message({
                    type: 'warning',
                    message: res.data.message,
                    showClose: true,
                    center: true
                }); 
                this.active = 3
                this.passAudit = false
               }
            //    被拒绝
               else if(res.data.status_code == 2){
                this.$message({
                    type: 'error',
                    message: res.data.message,
                    showClose: true,
                    center: true
                }); 
                this.active = 3
                this.passAudit = false
                this.refuse = true
               }else {
                this.$message({
                    type: 'error',
                    message: res.data.message,
                    showClose: true,
                    center: true
                });  
               }
            })
            .catch(error=>{
                console.log(error);
            })
        },
    }
    
}